import Link from 'next/link';
import { useTheme } from '@/contexts/ThemeContext';
import { useEffect, useState } from 'react';

export default function Header(): JSX.Element {
  const theme = useTheme();
  const { slug } = theme;
  const [MZAdress, setMZAdress] = useState('');

  useEffect(() => {
    if (slug !== null && slug?.includes('dl.')) {
      const domain = slug?.replace('dl', 'member');
      setMZAdress(`https://${domain}`);
    }
  }, []);

  return (
    <div className="bg-header border-b border-header top-0 inset-x-0 z-100">
      <div className="py-6 sm:py-8">
        <div className="container mx-auto sm:px-32 md:flex md:items-center md:justify-between text-center md:text-left">
          <div className="md:w-1/3">
            <Link href="/">
              <a
                aria-label="Home"
                className="text-white text-2xl md:text-lg font-bold"
              >
                <div className="w-full">
                  <img
                    src={
                      theme.slug !== 'eyesol'
                        ? '/images/DL_logo.png'
                        : '/images/DL_logo_2.png'
                    }
                    alt="Driver's license logo"
                    width="320"
                    height="32"
                  />
                </div>
              </a>
            </Link>
          </div>
          <div className="hidden mt-5 md:block md:w-1/6 md:mt-0">
            <a
              href={MZAdress ? MZAdress : process.env.NEXT_PUBLIC_MZ_ADDRESS}
              className="font-semibold flex items-center text-center block bg-transparent py-2 px-1 text-2xs lg:px-2 lg:text-sm"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-white"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                />
              </svg>
              <span className="text-white ml-2">Login</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
